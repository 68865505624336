import moment from "moment"

export const getEventDate = (event) => {
  const currentDate = moment().format("YYYY-MM-DD")

  const chilimaniaStartTime = moment(
    `${moment(currentDate).year()}-09-01 10:00:00`
  )
  const countryEdgeStartTime = moment(
    `${moment(currentDate).year()}-09-01 18:30:00`
  )

  let chilimaniaStartDate = moment(chilimaniaStartTime).add(
    daysToAdd(chilimaniaStartTime.day()),
    "days"
  )

  let countryEdgeStartDate = moment(countryEdgeStartTime)
    .add(daysToAdd(countryEdgeStartTime.day()), "days")
    .subtract(1, "days")

  if (moment(currentDate).isAfter(chilimaniaStartDate)) {
    chilimaniaStartDate.add(1, "y").subtract(2, "days")
    countryEdgeStartDate.add(1, "y").subtract(2, "days")
  }

  return event === "countryEdge"
    ? moment(countryEdgeStartDate).format("YYYY-MM-DD hh:mm:ss")
    : moment(chilimaniaStartDate).format("YYYY-MM-DD hh:mm:ss")
}

const daysToAdd = (day) => {
  let tempDays = 0
  switch (day) {
    case 0:
      tempDays = 6
      break
    case 1:
      tempDays = 5
      break
    case 2:
      tempDays = 11
      break
    case 3:
      tempDays = 10
      break
    case 4:
      tempDays = 9
      break
    case 5:
      tempDays = 8
      break
    case 6:
      tempDays = 7
      break
    default:
      break
  }
  return tempDays
}
